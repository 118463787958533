import React, { FC, useRef } from 'react';
import Slider from 'react-slick';
import SliderButton from '@components/common/sliderButton';
import ContentsListItem from '@components/common/contentsListItem';
import { ContentsListItemVO } from '@domain/vo/common/ContentsListItemVO';
import SkeletonList from '@components/index/contentsList/skeletonList';
import { usePostCardList } from '@repository/post/job/usePostJob';
import { LogTypeEnum } from '@/src/logging/LogType';
import { PostJobCategoryDto } from '@domain/rs/postJob/PostJobCategoryListRs';
import { V2Route } from '@utils/routeUtil';

import classnames from 'classnames/bind';
import styles from './contentsList.module.scss';
const cx = classnames.bind(styles);

// 컨텐츠 없는 경우
const EmptyList = () => {
  return (
    <div className={cx('emptyWrap')}>
      <div className={cx('emptyArea', 'contents')}>
        <strong className={cx('emptyTitle')}>새로운 콘텐츠를 준비중입니다.</strong>
      </div>
    </div>
  );
};

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;

  return (
    <SliderButton
      type={'prev'}
      slickClass={className}
      handleClick={onClick}
      style={{ ...style }}
      customClassName={cx('btnPrev')}
    />
  );
};

const NextArrow = (props: any) => {
  const { className, style, onClick } = props;

  return (
    <SliderButton
      type={'next'}
      slickClass={className}
      handleClick={onClick}
      style={{ ...style }}
      customClassName={cx('btnNext')}
    />
  );
};

interface PostJobThumbnailListProps {
  AllPostCardSns: number[];
  selectedCategory: PostJobCategoryDto;
  handleClick: (contentData: ContentsListItemVO) => boolean;
}

const PostJobThumbnailList: FC<PostJobThumbnailListProps> = ({ AllPostCardSns, selectedCategory, handleClick }) => {
  const dragging = useRef(false);
  const categorySns = selectedCategory.iconType === 'ALL' ? AllPostCardSns : [selectedCategory.sn];
  //카테고리별 콘텐츠 썸네일 리스트
  const { data: thumbnailsListData } = usePostCardList(true, { size: 12, categorySns });
  const isEmpty = !thumbnailsListData?.pages[0].posts.length;
  const contentsListEl = thumbnailsListData?.pages.map((list) => {
    return list.posts.map((item) => {
      const contentData = new ContentsListItemVO({
        ...item,
        name: item.categoryName,
        tag: item.label,
        routeUrl: V2Route.getInfoDetailPage(item.sn),
      });

      return (
        <div className={cx('listItemWrap')} key={item.sn} id={LogTypeEnum.log_info_card}>
          <ContentsListItem contentData={contentData} handleClick={handleClick} handleDrag={() => !dragging.current} />
        </div>
      );
    });
  });

  const settings = {
    infinite: false,
    arrows: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    slidesToShow: 4,
    slidesToScroll: 4,
    speed: 300,
    draggable: true,
    beforeChange: () => (dragging.current = true),
    afterChange: () => (dragging.current = false),
  };

  if (!thumbnailsListData) return <SkeletonList />;
  if (isEmpty) return <EmptyList />;

  return (
    <div className={cx('contentsListWrap')}>
      <Slider {...settings}>{contentsListEl}</Slider>
    </div>
  );
};

export default PostJobThumbnailList;
