import { PostJobContentType } from '@domain/constant/postJob/PostJobContentType';
import { PrivacyType } from '@domain/constant/privacyType';

export class ContentsListItemVO {
  name: string;
  title: string;
  thumbnailUrl?: string;
  type?: PostJobContentType;
  tag: string;
  routeUrl: string;
  privacy: PrivacyType;
  link?: string;
  sn: number;

  constructor(dto: ContentsListItemVO) {
    this.name = dto.name;
    this.title = dto.title;
    this.thumbnailUrl = dto.thumbnailUrl;
    this.type = dto.type;
    this.tag = dto.tag;
    this.routeUrl = dto.routeUrl;
    this.privacy = dto.privacy;
    this.link = dto.link;
    this.sn = dto.sn;
  }
}
