import { useInfiniteQuery, useQuery } from 'react-query';
import { RemotePostJobRepo } from '@repository/post/job/PostJobRepo';
import { PostCategoryType } from '@domain/rq/post/PostCategoryListRq';
import { PostJobCardVO } from '@domain/vo/postJob/PostJobCardVO';

const remotePostJobRepo = new RemotePostJobRepo();

const keys = ['postJob'];

export const fetchPostCategoryList = async (type?: PostCategoryType) => {
  const { data } = await remotePostJobRepo.fetchGetCategorySpace(type);
  return {
    ...data,
    categorySns: data.categories.map((category) => category.sn),
  };
};

const fetchPostCardList = async (params: { page: number; size: number; categorySns: number[] }) => {
  const { data } = await remotePostJobRepo.fetchGetPostCardList(params);
  return {
    ...data,
    posts: data.posts.map((item) => new PostJobCardVO(item)),
  };
};

function usePostJobCategories(type?: PostCategoryType) {
  return useQuery([...keys, 'categories', 'space', 'job', type], () => fetchPostCategoryList(type));
}

function usePostCardList(enabled: boolean, params: { page?: number; size: number; categorySns: number[] }) {
  return useInfiniteQuery(
    [...keys, 'post', 'card', params],
    ({ pageParam }) => fetchPostCardList({ ...params, page: params.page ?? pageParam }),
    {
      getNextPageParam: ({ pages: { page, totalPages } }) => (page < totalPages ? page + 1 : undefined),
      enabled,
      keepPreviousData: true,
    },
  );
}

export { keys as postJobKeys, usePostJobCategories, usePostCardList };
