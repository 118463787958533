import React, { FC } from 'react';
import Link from 'next/link';
import Lottie from 'react-lottie';
import Modal from '@common/modal';
import CloseButton from '@common/closeButton';
import Text from '@components/common/text';
import toggle from '@graphic/toggle.json';
import { FontStyle } from '@domain/constant/fontStyle';
import Colors from '@domain/constant/colors';
import { RouteUrl } from '@utils/routeUtil';
import classNames from 'classnames/bind';
import styles from './index.module.scss';

const cx = classNames.bind(styles);

const options = {
  loop: true,
  autoplay: true,
  animationData: toggle,
};

interface Props {
  visible: boolean;
  onClose?: () => void;
}

const NoMatchingUserModal: FC<Props> = ({ visible, onClose }) => {
  return (
    <>
      <Modal visible={visible}>
        <div className={cx('layerCertification')}>
          <Text fontStyle={FontStyle.H4_B}>
            잡다매칭에 참여 중인 회원에게만
            <br />
            공개된 콘텐츠입니다.
          </Text>
          <Text className={cx('description')} fontStyle={FontStyle.B2P_M} color={Colors.C_COOL_GRAY_70}>
            {`매칭 설정 > 제안 받기를 켜주세요.`}
          </Text>

          <div className={cx('toggleArea')}>
            <Text fontStyle={FontStyle.B2P_M} color={Colors.C_COOL_GRAY_70}>
              매치 설정
            </Text>
            <div className={cx('lottieArea')}>
              <Text fontStyle={FontStyle.B2_B}>제안 받기</Text>
              <Lottie options={options} />
            </div>
          </div>

          <Link className={cx('btn')} href={RouteUrl.MATCH_APPLY}>
            잡다매칭 참여하러 가기
          </Link>

          <CloseButton className={cx('btnClose', { hidden: !onClose })} onClick={onClose} />
        </div>
      </Modal>
    </>
  );
};

export default NoMatchingUserModal;
