import React from 'react';

import classnames from 'classnames/bind';
import styles from './skeletonList.module.scss';
const cx = classnames.bind(styles);

const SkeletonList = () => {
  return (
    <div className={cx('skeletonArea')}>
      <div className={cx('skeletonListItem')}>
        <div className={cx('image')} />
        <div className={cx('bar', 'type')} />
        <div className={cx('bar', 'title')} />
        <div className={cx('bar', 'title')} />
        <div className={cx('bar', 'subInfo')} />
      </div>
      <div className={cx('skeletonListItem')}>
        <div className={cx('image')} />
        <div className={cx('bar', 'type')} />
        <div className={cx('bar', 'title')} />
        <div className={cx('bar', 'title')} />
        <div className={cx('bar', 'subInfo')} />
      </div>
      <div className={cx('skeletonListItem')}>
        <div className={cx('image')} />
        <div className={cx('bar', 'type')} />
        <div className={cx('bar', 'title')} />
        <div className={cx('bar', 'title')} />
        <div className={cx('bar', 'subInfo')} />
      </div>
      <div className={cx('skeletonListItem')}>
        <div className={cx('image')} />
        <div className={cx('bar', 'type')} />
        <div className={cx('bar', 'title')} />
        <div className={cx('bar', 'title')} />
        <div className={cx('bar', 'subInfo')} />
      </div>
    </div>
  );
};

export default SkeletonList;
