import React, { FC } from 'react';
import { PostJobCategoryDto } from '@domain/rs/postJob/PostJobCategoryListRs';

import classnames from 'classnames/bind';
import styles from './contentsList.module.scss';
const cx = classnames.bind(styles);

interface PostJobCategoryListProps {
  selectedCategory: PostJobCategoryDto;
  setSelectedCategory: (selected: PostJobCategoryDto) => void;
  categoryList: PostJobCategoryDto[];
}

const PostJobCategoryList: FC<PostJobCategoryListProps> = ({ selectedCategory, setSelectedCategory, categoryList }) => {
  const categoryListEl = [...categoryList].map((item) => {
    return (
      <a
        className={cx('btnFilter', { on: selectedCategory.sn === item.sn })}
        role="button"
        key={item.sn}
        onClick={() => setSelectedCategory(item)}
      >
        {item.name}
      </a>
    );
  });

  return <div className={cx('filterArea')}>{categoryListEl}</div>;
};

export default PostJobCategoryList;
