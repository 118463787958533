import React, { MouseEvent } from 'react';
import { useRouter } from 'next/router';
import Icon from '@common/assets';
import EllipsisText from '@common/ellipsisText';
import Image from '@components/common/image';
import { ContentsListItemVO } from '@domain/vo/common/ContentsListItemVO';
import { LogTypeEnum } from '@/src/logging/LogType';
import Colors from '@domain/constant/colors';

import classnames from 'classnames/bind';
import styles from './contentsListItem.module.scss';
const cx = classnames.bind(styles);

interface contentsListItemProps {
  contentData: ContentsListItemVO;
  handleDrag?: (e: MouseEvent<HTMLAnchorElement>) => boolean;
  // 다크모드 대응(ex. 개발자검사 센터)
  theme?: 'dark' | '';
  handleClick?: (contentData: ContentsListItemVO) => boolean;
}

const ContentsListItem = ({ contentData, handleDrag, theme, handleClick }: contentsListItemProps) => {
  const { sn, name, title, thumbnailUrl, tag, routeUrl } = contentData;
  const router = useRouter();
  const contentsImageEl = (
    <div className={cx('imgWrap')}>
      {thumbnailUrl ? (
        <Image key={sn} src={thumbnailUrl} width={282} height={212} alt={title} pointer />
      ) : (
        <Icon name="logo" width={160} height={36} fill={Colors.C_COOL_GRAY_50} />
      )}
    </div>
  );

  const handleOnClick = (e: MouseEvent<HTMLAnchorElement>) => {
    const notDragging = handleDrag ? handleDrag(e) : true;
    const canViewContent = handleClick ? handleClick(contentData) : true;

    if (notDragging && canViewContent) {
      router.push(routeUrl);
    }
  };

  return (
    <a className={cx('link', theme)} role="button" onClick={handleOnClick} id={LogTypeEnum.log_info_card}>
      <div className={cx('imgArea', { default: !thumbnailUrl })}>{contentsImageEl}</div>

      <div className={cx('infoArea')}>
        <div className={cx('contentsType')}>{name}</div>
        <strong className={cx('title')}>{title}</strong>
        {!!tag && (
          <div className={cx('subInfoArea')}>
            <EllipsisText text={tag} type={'height'} offset={36} customClassName={cx('subInfoWrap')} />
          </div>
        )}
      </div>
    </a>
  );
};

export default ContentsListItem;
