import _axios, { AxiosResponse } from 'axios';
import { axios } from '@repository/RequestClient';
import { PostCategoryType } from '@domain/rq/post/PostCategoryListRq';
import { PostJobCategoryListRs } from '@domain/rs/postJob/PostJobCategoryListRs';
import { PostJobDetailsRs } from '@domain/rs/postJob/PostJobDetailsRs';
import { PostJobCardListRs } from '@domain/rs/postJob/PostJobCardListRs';

const { CancelToken } = _axios;

const getUri = (path: string, withPostfix = false): string => {
  const getJobPrefix = '/post';
  const getJobPostfix = '?type=JOB';

  if (withPostfix) return `${getJobPrefix}${path}${getJobPostfix}`.trim();

  return `${getJobPrefix}${path}`.trim();
};

export default interface PostJobRepo {
  // 카테고리(탭) 조회
  fetchGetJobCategories(): Promise<AxiosResponse<PostJobCategoryListRs>>;

  // 콘텐츠 상세조회
  fetchGetJobDetails(postSn: number): Promise<AxiosResponse<PostJobDetailsRs>>;

  //콘텐츠 조회 취소
  cancelFetchGetJobCardList(): void;

  //전체 콘텐츠 조회
  fetchGetJobCardList(page: number, size: number): Promise<AxiosResponse<PostJobCardListRs>>;

  // 카테고리(탭) 별 콘텐츠 조회
  fetchGetJobCardListWithCategory(
    categorySn: number,
    page: number,
    size: number,
  ): Promise<AxiosResponse<PostJobCardListRs>>;

  // 공간(URL) 별 탭 목록 조회
  fetchGetCategorySpace(type: PostCategoryType): Promise<AxiosResponse<PostJobCategoryListRs>>;

  // 탭 별 콘텐츠 목록 조회
  fetchGetPostCardList(params: {
    page: number;
    size: number;
    categorySns: number[];
  }): Promise<AxiosResponse<PostJobCardListRs>>;
}

export class RemotePostJobRepo implements PostJobRepo {
  cancelerFetchGetJobCardList: Function | null = null;
  cancelerFetchGetJobCardListWithCategory: Function | null = null;

  fetchGetJobCategories(): Promise<AxiosResponse<PostJobCategoryListRs>> {
    return axios.get(getUri('/categories', true));
  }

  fetchGetJobDetails(postSn: number): Promise<AxiosResponse<PostJobDetailsRs>> {
    return axios.get(getUri(`/${postSn}`));
  }

  cancelFetchGetJobCardList(): void {
    if (this.cancelerFetchGetJobCardList) this.cancelerFetchGetJobCardList();
    if (this.cancelerFetchGetJobCardListWithCategory) this.cancelerFetchGetJobCardListWithCategory();
  }

  fetchGetJobCardList(page: number, size: number): Promise<AxiosResponse<PostJobCardListRs>> {
    return axios.get(getUri('/thumbnails', true), {
      cancelToken: new CancelToken((c) => {
        this.cancelFetchGetJobCardList = c;
      }),
      params: { page, size },
    });
  }

  fetchGetJobCardListWithCategory(
    categorySn: number,
    page: number,
    size: number,
  ): Promise<AxiosResponse<PostJobCardListRs>> {
    return axios.get(getUri(`/${categorySn}/thumbnails`), {
      cancelToken: new CancelToken((c) => {
        this.cancelerFetchGetJobCardListWithCategory = c;
      }),
      params: { page, size },
    });
  }

  fetchGetCategorySpace(type?: PostCategoryType): Promise<AxiosResponse<PostJobCategoryListRs>> {
    return axios.get<PostJobCategoryListRs>(getUri('/category/space'), {
      params: { type },
    });
  }

  fetchGetPostCardList(params: {
    page: number;
    size: number;
    categorySns: number[];
  }): Promise<AxiosResponse<PostJobCardListRs>> {
    return axios.get('/post', {
      cancelToken: new CancelToken((c) => {
        this.cancelFetchGetJobCardList = c;
      }),
      params,
    });
  }
}
